<template>
    <div
        v-if="windowWidth >= 800"
        class="d-flex"
    >
        <div>
            <span
                class="link"
                @click="$router.replace({name: 'start'})"
            >
                Startseite
            </span>
        </div>

        <div
            v-for="el in this.route"
            :key="el.text"
        >
            <span class="mx-2">/</span>
            <span style="text-decoration: underline">{{ el.text }}</span>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "Breadcrumbs",
    props: {
        route: { type: Array, required: false },
    },
    computed: {
        ...mapState('util', ['windowWidth']),
    },
    mounted() {},
}
</script>

<style lang="scss" scoped>
div {
    span {
        font-size: 22px;
    }
}

.link {
    cursor: pointer;
}
</style>
