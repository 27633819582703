<template>
    <div id="background">
        <div
            :style="windowWidth < 800 ? 'margin: 2vh 16px 0 16px' : 'margin: 2vh 20vw 4vh 20vw'"
            style="padding-bottom: 100px;"
        >
            <!-- header -->
            <div class="mb-2">
                <Breadcrumbs :route="crumbs" />

                <div
                    class="d-flex align-center justify-space-between "
                    style="height: 40px"
                >
                    <v-btn
                        class="text-none"
                        elevation="0"
                        tile
                        color="red"
                        dark
                        @click="handleGoBack"
                    >
                        zurück
                    </v-btn>

                    <v-text-field
                        v-if="showAllCompanies"
                        v-model="searchString"
                        :style="windowWidth < 800 ? '' : 'max-width: 50%;'"
                        style="border-radius: 0;"
                        background-color="white"
                        placeholder="Betrieb suchen..."
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        hide-details
                        dense
                        clearable
                        @input="searchInputChanged"
                    />
                </div>
            </div>

            <!-- specific category -->
            <div
                v-if="category"
                class="formContainer pa-4"
            >
                <div class="d-flex justify-space-between align-start my-4">
                    <p style="font-size: 24px">
                        <b>Praktikumsbereich: {{ category.name }}</b>
                    </p>
                    <img
                        v-if="category.file"
                        :src="category.file"
                        style="max-width: 80px; max-height: 80px"
                        class="mx-4"
                        alt=""
                    >
                </div>

                <p>{{ category.description }}</p>

                <div
                    v-if="category.companies.length > 0"
                    class="mt-4"
                >
                    <p
                        style="font-size: 24px"
                        class="mb-2"
                    >
                        <b>Diese Betriebe sind in diesem Bereich tätig:</b>
                    </p>

                    <div class="categoryContainer">
                        <div
                            v-for="company in category.companies"
                            :key="company.name"
                            class="category"
                            style="cursor: pointer"
                            @click="goToCompany(company)"
                        >
                            <img
                                v-if="company.logo"
                                :src="company.logoFile"
                                class="icon"
                                alt=""
                            >
                            <p
                                class="text-center"
                                style="overflow-wrap: anywhere"
                            >
                                {{ company.name }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- all companies with search -->
            <div
                v-else-if="showAllCompanies"
                class="d-flex flex-column align-center"
                style="width: 100%"
            >
                <p
                    style="font-size: 24px; font-weight: bold"
                    class="mb-8"
                >
                    Alle Betriebe von A-Z
                </p>

                <div>
                    <div class="categoryContainer">
                        <div
                            v-for="company in filteredCompanies"
                            :key="company.name"
                            class="category"
                            style="cursor: pointer"
                            @click="goToCompany(company)"
                        >
                            <img
                                v-if="company.logo"
                                :src="company.logo"
                                class="icon"
                                alt=""
                            >
                            <p
                                class="text-center"
                                style="overflow-wrap: anywhere"
                            >
                                {{ company.name }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- categories -->
            <div v-else>
                <div class="d-flex flex-column justify-center align-center">
                    <img
                        :src="intern"
                        style="width: 120px"
                        alt=""
                    >
                    <p
                        style="font-size: 30px; font-weight: bold"
                        class="mb-8"
                    >
                        <b>Praktikumsbereiche</b>
                    </p>
                </div>

                <div class="categoryContainer">
                    <div
                        class="category"
                        @click="showAllCompanies = true"
                    >
                        <img
                            :src="lupeIcon"
                            style="width: 55px"
                            alt="Lupe"
                        />
                        <p class="text-center">
                            Alle Betriebe von A-Z
                        </p>
                    </div>

                    <div
                        v-for="(category, id) in categories"
                        :key="id"
                        class="category"
                        @click="openCategory(category)"
                    >
                        <img
                            v-if="category.file"
                            :src="category.file"
                            alt=""
                        >
                        <p
                            class="text-center"
                            style="overflow-wrap: anywhere"
                        >
                            {{ category.name }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import * as backend from "@/api/backend";
import Breadcrumbs from "@/components/Breadcrumbs";
import lupeIcon from '@/assets/magnifier.png'
import intern from '@/assets/intern.png'

export default {
    name: "Intern",
    components: {Breadcrumbs},
    data() {
        return {
            categories: [],

            showAllCompanies: false,
            allCompanies: [],
            searchString: '',
            filteredCompanies: [],

            category: '',

            crumbs: [
                {
                    text: 'Praktikum',
                }
            ],

            // Icons
            lupeIcon,
            intern,
        }
    },
    computed: {
        ...mapState('util', ['windowWidth']),
    },
    async mounted() {
        await this.requestCompanies();
        await this.requestCategories();
    },
    methods: {
        ...mapActions('company', ['getAllCompanies']),
        ...mapActions('category', ['getCategories']),

        async requestCompanies() {
            this.allCompanies = this.filteredCompanies = await this.getAllCompanies();
            this.filteredCompanies.sort((a, b) => a.name.localeCompare(b.name));

            // request the logo/image for each company, if it has one
            for (let i = 0; i < this.allCompanies.length; i++) {
                if (this.allCompanies[i].logo) {
                    const categoryImageResponse = await backend.getFile(this.allCompanies[i].logo);
                    const categoryImageBlob = await categoryImageResponse.blob();
                    this.allCompanies[i].logo = URL.createObjectURL(categoryImageBlob);
                }
            }
        },

        async requestCategories() {
            this.categories = await this.getCategories();
            this.categories.sort((a, b) => a.name.localeCompare(b.name));

            // request the logo/image for each category, if it has one
            for (let i = 0; i < this.categories.length; i++) {
                if (this.categories[i].file) {
                    const categoryImageResponse = await backend.getFile(this.categories[i].file);
                    const categoryImageBlob = await categoryImageResponse.blob();
                    this.categories[i].file = URL.createObjectURL(categoryImageBlob);
                }
            }
        },

        async openCategory(category) {
            for (let i = 0; i < category.companies.length; i++) {
                if (category.companies[i].logo) {
                    const companyImageResponse = await backend.getFile(category.companies[i].logo);
                    const companyImageBlob = await companyImageResponse.blob();
                    category.companies[i].logoFile = URL.createObjectURL(companyImageBlob);
                }
            }

            this.category = category;
        },

        goToCompany(company) {
            // change route/URL and add ?companyId=<company._id>, the query will then be used by company component
            this.$router.push({ name: 'company', query: { companyId: company._id }});
        },

        searchInputChanged() {
            this.filteredCompanies = this.allCompanies
                .filter(company => company.name.toLowerCase().includes(this.searchString.toLowerCase()));
        },

        handleGoBack() {
            if (this.category) {
                this.category = '';
            }
            else if (this.showAllCompanies) {
                this.showAllCompanies = false;
            }
            else {
                this.$router.replace({name: 'start'});
            }
        },
    },
}
</script>

<style scoped lang="scss">
#background {
    background-color: lightgrey;
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../assets/Praktikum.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
}

.formContainer {
    border: 1px solid black;
    background-color: white;
    width: 100%;
}

.categoryContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
}

.icon {
    height: 100px;
    max-height: 100px;
    max-width: 135px;
}

.category {
    background-color: white;
    border: 2px black solid;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.7);
    padding: 4px;
    width: 150px;
    /*width: fit-content;*/
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4px;
    cursor: pointer;
    overflow: hidden;

    img {
        max-height: 80px;
        max-width: 80px;
    }
}

.category:hover {
    filter: brightness(0.8);
}
</style>

