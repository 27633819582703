<template>
    <div
        id="content"
        class="d-flex"
    >
        <!-- DEV BUTTONS -->
        <div
            v-if="false"
            id="devBtns"
        >
            <v-btn
                class="text-none"
                dark
                @click="createCategories"
            >
                Kat.s init.
            </v-btn>

            <v-btn
                class="ml-2 text-none"
                dark
                @click="dialog = true"
            >
                + Kat.
            </v-btn>
        </div>

        <v-overlay
            :value="trailer"
            color="white"
        >
            <img
                class="mainLogo"
                style="width: 100vw"
                :src="mosbach_logo"
                alt=""
            >
        </v-overlay>

        <div>
            <img
                class="mainLogo"
                style="position: absolute; margin-left: 20px; margin-top: 15px; z-index: 100"
                :style="windowWidth > 800 ? 'width: 12.5vw' : 'display: none'"
                :src="mosbach_logo"
                alt=""
                @click="$router.replace('start')"
            >
        </div>

        <v-dialog
            v-model="dialog"
        >
            <v-card>
                <v-card-text>
                    <p>Kategoriename und -beschreibung sind hardcoded</p>
                    <span
                        class="mr-2"
                    >
                        Logo hochladen:
                    </span>
                    <input
                        id="logo"
                        ref="logoInput"
                        type="file"
                        accept="image/*"
                    >

                    <v-btn
                        @click="createCategory"
                    >
                        Erstellen
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <div
            id="trainingBackground"
            class="cardContainer"
        >
            <div
                id="trainingCard"
                class="card"
                @click="replaceRouter('training')"
            >
                <div>
                    <img
                        :src="training"
                        alt=""
                    >
                    <div>
                        <p>Bewerbungstraining</p>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="internBackground"
            class="cardContainer"
        >
            <div
                id="internCard"
                class="card"
                @click="replaceRouter('intern')"
            >
                <div>
                    <img
                        :src="intern"
                        alt=""
                    >
                    <div>
                        <p>Praktikumsbereich</p>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="apprenticeBackground"
            class="cardContainer"
        >
            <div
                id="apprenticeCard"
                class="card"
                @click="replaceRouter('apprentice')"
            >
                <div>
                    <img
                        :src="apprentice"
                        alt=""
                    >
                    <div>
                        <p>Ausbildungsbereich</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Buttons in den Ecken -->

        <v-btn
            id="adminBereich"
            class="eckButtons text-none"
            elevation="0"
            text
            dark
            large
            @click="forwardRoute"
        >
            <p style="font-size: large; color: black">
                Admin
            </p>
        </v-btn>

        <v-btn
            id="impressumBtn"
            class="eckButtons text-none"
            elevation="0"
            text
            dark
            large
            @click="replaceRoute('impressum')"
        >
            <p style="font-size: large; color: black">
                Impressum
            </p>
        </v-btn>

        <div
            id="supoorters"
            class="d-flex justify-space-between align-baseline"
            :style="windowWidth < 800 ? 'display: none !important' : ''"
        >
            <a href="https://aktives-gewerbe.de/gewerbe/index.php/de/">
                <img :src="logoGewerbeVerein">
            </a>
<!--            <a href="https://www.mosbach.de/Kontatktdaten_Stadtverwaltung-no_mobile-1.html">
                <img :src="logoMosbach">
            </a>-->
            <p style="font-size: large; color: black">
                Unterstützer
            </p>
        </div>
    </div>
</template>

<script>
import training from '@/assets/training.png'
import intern from '@/assets/intern.png'
import apprentice from '@/assets/apprentice.png'
import {mapActions, mapMutations, mapState} from "vuex";
import mosbach_logo from "@/assets/logo2.png";
import * as backend from "@/api/backend";
import logoGewerbeVerein from '@/assets/logo_supporter_1.jpg';
import logoMosbach from '@/assets/logo_supporter_2.jpg';
import apples from '@/assets/categories/apples.jpg';
import axe from '@/assets/categories/axe.jpg';
import binders from '@/assets/categories/binders.jpg';
import color from '@/assets/categories/color.jpg';
import firstAid from '@/assets/categories/first_aid.jpg';
import fork from '@/assets/categories/fork.jpg';
import pc from '@/assets/categories/pc.png';
import heart from '@/assets/categories/heart.jpg';
import stabelgapler from '@/assets/categories/stabelgapler.jpg';
import hand from '@/assets/categories/hand.jpg';
import block from '@/assets/categories/block.jpg';
import {getCategories} from "../api/backend";

export default {
    name: "Home",
    components: {},
    props: {},
    data() {
        return {
            training,
            intern,
            apprentice,

            trailer: false,     // only on mobile

            dialog: false,

            mosbach_logo,
            logoMosbach,
            logoGewerbeVerein,
        };
    },
    computed: {
        ...mapState('util', ['windowWidth']),
    },
    watch: {
        trailer (val) {
            val && setTimeout(() => {
                this.trailer = false
            }, 1600)
        },
    },
    mounted() {
        if (this.windowWidth < 800) {
            this.trailer = true;
        }
    },
    beforeDestroy() {
    },
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapActions('category', ['postCategory', 'uploadLogoCategory', 'getCategories']),

        ...mapMutations('admin', ['setAdminToken']),

        async forwardRoute() {
            let pw = window.prompt('Bitte geben Sie das Passwort ein:');
            let res = await backend.validateAdminPassword(pw);
            if (res.status === 403) {
                window.alert('Ungültiges Passwort');
                this.setAdminToken('');
                return;
            }
            res = await res.json();
            this.setAdminToken(res.value);
            await this.$router.replace('admin').catch(()=>{});
        },

        replaceRouter(route) {
            this.$router.push({name: route});
        },

        replaceRoute(route) {
            // also clear query from going to a specific company
            this.$router.replace({name: route, query: { companyId: undefined }});
        },

        async createCategories() {
            let cats = [
                {
                    name: 'Kosmetik',
                    info: 'Hier lernst du wie du verschiedene Hauttypen ' +
                        'erkennst und beräts die Kunden und Kundinnen ' +
                        'über Körper- und Schönheitspflege. Du führst ' +
                        'dann die passende Behandlung durch. Du reinigst ' +
                        'z.B. die Haut, oder entfernst störende ' +
                        'Körperbehaarung. Du trägst Make-up oder ' +
                        'Masken auf und pflegst Finger- und Fußnägel. ' +
                        'Außerdem verkaufst du auch Pflegeprodukte.',
                    img: hand
                },
                {
                    name: 'Holz',
                    info: 'Hier arbeitest du mit dem Rohstoff Holz. Du stellst ' +
                        'Holzbauteile oder fertigst Holzprodukte her. ' +
                        'Berufe in dem Bereich sind z.B. Holzbearbeiter/in ' +
                        'oder Tischler/in.',
                    img: axe,
                },
                {
                    name: 'Agrarwirtschaft',
                    info: 'Hier abreitest du mit Pflanzen. Du pflanzt sie ein ' +
                        'und pflegst sie. Du kümmerst dich um die richtige ' +
                        'Erde und planst Gärten. Hier arbeitest du ' +
                        'meistens draußen oder in Gewächshäusern.',
                    img: apples,
                },
                {
                    name: 'Informatik',
                    info: 'Hier arbeitest du mit dem PC und lernst ' +
                        'verschiedene Betriebssysteme kennen. Du ' +
                        'programmierst Software und suchst Fehler in ' +
                        'Software, die nicht mehr richtig funktioniert.',
                    img: pc,
                },
                { name: 'Ernährung und Gastro', info: 'Hier bedienst du Gäste, indem du im Restaurant ' +
                        'Bestellungen aufnimmst und servierst. Außerdem ' +
                        'kannst du an der Bar arbeiten und Getränke ' +
                        'zubereiten. In der Küche bereitest du einfache ' +
                        'Gerichte zu. Im Zimmerservice sorgst du dafür, ' +
                        'dass die Räume für die Gäste sauber und ' +
                        'einladend hergerichtet sind.',
                    img: fork
                },
                { name: 'Lager und Handel', info: 'Hier arbeitest du mit Waren, Produkten,' +
                        'Materialien, Bauteilen. Du musst sie einräumen, ' +
                        'auspacken, verpacken, verladen und versenden. ' +
                        'Außerdem fährst du Transportgeräte und ' +
                        'Hubgeräte.\n' +
                        'Du kannst in Transportbetrieben und Speditionen ' +
                        'arbeiten. Aber auch in Industrie und Handel im ' +
                        'Wareneingang und Warenausgang, im Lager und' +
                        'im Versand.',
                    img: stabelgapler
                },
                {
                    name: 'Farbtechnik',
                    info: 'Hier führst du Neulackierungen und ' +
                        'Umlackierungen durch und besserst Lackschäden ' +
                        'an Fahrzeugen aus. Das kannst du mit ' +
                        'Maschinen oder mit den Händen machen. Du ' +
                        'wirst meistens in Werkstätten und Werkhallen, in ' +
                        'Lackierkabinen und im Außenbereich arbeiten.',
                    img: color
                },
                {
                    name: 'Gesundheit und Soziales',
                    info: 'Hier arbeitest du vor allem mit Menschen ' +
                        'zusammen. Hierbei ist dir das körperliche und ' +
                        'seelische Wohl der Menschen wichtig. Es gibt ' +
                        'unterschiedliche Berufe in diesem Bereich z.B. ' +
                        'Altenpfleger/in oder Erzieher/in.',
                    img: firstAid
                },
                {
                    name: 'Wirtschaft und Verwaltung',
                    info: 'Du kannst mit einer Ausbildung in diesem Bereich ' +
                        'in sehr vielen Betrieben arbeiten. Zu deinen ' +
                        'Hauptaufgaben gehören z.B. die Post deines ' +
                        'Betriebes sortieren, geschäftliche Briefe ' +
                        'schreiben, Sekretariatsarbeiten und Waren an- und verkaufen.',
                    img: binders
                },
                {
                    name: 'Bautechnik',
                    info: 'Hier verrichtest du z.B. Maurer- und ' +
                        'Betonarbeiten. Außerdem gehört zu dem Bereich das Dachdecken, ' +
                        'Fassadenverkleidungen, aber auch das Sanieren von Gebäuden. ' +
                        'Hier arbeitest du viel mit deinem Körper.',
                    img: color
                },
                {
                    name: 'Metall',
                    info: 'Hier stellst du Bauteile für den Maschinen- oder Fahrzeugbau her.\n' +
                        'Du verwendest dafür Werkzeug oder computergesteuerten Werkzeugmaschinen.',
                    img: block
                },
                {
                    name: 'Sonstiges',
                    info: 'Alle anderen Berufe',
                    img: heart
                },
            ];

            let body;

            for (let i = 0; i < cats.length; i++) {
                body = {
                    name: cats[i].name,
                    description: cats[i].info,
                };

                const categoryResponse = await this.postCategory(body);

                if (cats[i].img) {
                    const imgResponse = await fetch(cats[i].img);
                    const imgBlob = await imgResponse.blob();
                    const imgFile = new File([imgBlob], cats[i].name + '.jpg', { type: 'image/jpeg' });
                    imgFile.categoryId = categoryResponse._id;
                    await this.uploadLogoCategory(imgFile);
                }
            }
        },

        async createCategory() {
            let body = {
                name: 'Ernährung',
                description: 'Süßigkeiten erfinden',
            };

            let res = await this.postCategory(body);

            let file = this.$refs.logoInput.files[0];
            if (file) {
                file.categoryId = res._id;
                await this.uploadLogoCategory(file);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.mainLogo {
    cursor: pointer;
}

.mainLogo:hover {
    filter: brightness(0.8);
}

#trainingBackground {
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../assets/Training Kachel.jpg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;
}
#trainingCard {
    height: 100%;
    background-color: rgba(0, 255, 0, 0.5);
}
#internBackground {
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../assets/Praktikum Kachel.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
#internCard {
    height: 100%;
    background-color: rgba(255, 0, 0, 0.5);
}
#apprenticeBackground {
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../assets/Ausbildung Kachel.jpg");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
}
#apprenticeCard {
    height: 100%;
    background-color: rgba(0, 0, 255, 0.5);
}

.cardContainer {
    height: calc(100vh);
    min-height: calc(100vh);
    width: calc(100% / 3);
    overflow: hidden;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    div {
        display: flex;
        flex-direction: column;
        gap: 40px;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            padding: 16px;
            background-color: rgba(255, 255, 255, 0.8);
        }
    }
}
.card:hover {
    overflow: hidden;
    transform: scale(1.25);
}

.eckButtons {
    position: fixed;
}

.eckButtons:hover {
    text-decoration: underline;
    text-decoration-color: black;
}

#adminBereich {
    top: 4px;
    right: 4px;
}

#impressumBtn {
    bottom: 4px;
    right: 4px;
}

#supoorters {
    position: fixed;
    bottom: 4px;
    left: 4px;
    padding-left: 10px;

    p {
        color: white;
    }

    img {
        margin-right: 10px;
        max-width: 100px;
    }

    img:hover {
        filter: brightness(0.8);
    }
}

#devBtns {
    position: absolute;
    top: 20px;
    left: 500px;
    z-index: 10 !important;
}

@media (max-width: 800px) {
    .card {
        div {
            gap: 10px;

            div {
                padding: 4px;
                font-size: 20px;
            }
        }
    }

    #impressumBtn {
        top: 4px;
        left: 4px;
    }

    #content {
        flex-direction: column;
    }

    .cardContainer {
        height: calc((100vh - 96px) / 3);
        min-height: calc((100vh - 96px) / 3);
        width: 100vw;
    }

    #devBtns {
        top: 4px;
        left: 4px;

        .v-btn {
            margin-top: 2px !important;
            margin-left: 2px !important;
        }
    }
}
</style>
