<template>
    <div>
        <v-overlay
            v-if="infoDialog"
            opacity="0.80"
            @click="infoDialog = false"
        />

        <!-- desktop -->
        <div
            v-if="windowWidth >= 800"
            class="d-flex flex-column background"
            style="position: absolute; height: 100vh; min-height: 100vh;"
        >
            <Breadcrumbs
                :route="crumbs"
                class="pl-4 pt-4"
            />

            <div
                class="d-flex justify-space-between align-center pa-4 pt-0"
                style="width: 100%;"
            >
                <v-btn
                    v-if="$route.name !== 'start'"
                    class="text-none"
                    elevation="0"
                    tile
                    color="red"
                    dark
                    @click="$router.replace({name: 'start'})"
                >
                    zurück
                </v-btn>
                <div>
                    <img
                        :src="training"
                        alt=""
                    >
                    <p class="text-center">
                        <b>Training</b>
                    </p>
                </div>

                <p />
            </div>

            <div id="content">
                <div
                    id="areaContainer"
                    class="d-flex justify-space-between"
                >
                    <div
                        class="boxContainer"
                        @click="openInfoBox(boxes[0])"
                    >
                        <img
                            :src="magnifier"
                            alt=""
                        >
                        <p class="text-center">
                            1. Wo und wie?
                        </p>
                    </div>

                    <div
                        class="boxContainer"
                        @click="openInfoBox(boxes[1])"
                    >
                        <img
                            :src="documents"
                            alt=""
                        >
                        <p class="text-center">
                            2. Bewerbungsunterlagen
                        </p>
                    </div>

                    <div
                        class="boxContainer"
                        @click="openInfoBox(boxes[2])"
                    >
                        <img
                            :src="dialog"
                            alt=""
                        >
                        <p class="text-center">
                            3. Vorstellungsgespräche
                        </p>
                    </div>

                    <div
                        class="boxContainer"
                        @click="openInfoBox(boxes[3])"
                    >
                        <img
                            :src="idea"
                            alt=""
                        >
                        <p class="text-center">
                            4. Weitere Tipps
                        </p>
                    </div>
                </div>

                <div id="arrow" />
            </div>
        </div>

        <!-- mobile -->
        <div
            v-else
            class="background"
            style="background-color: lightgrey; height: 100vh; min-height: 100vh"
        >
            <v-btn
                v-if="$route.name !== 'start'"
                class="text-none ma-4"
                elevation="0"
                tile
                color="red"
                dark
                @click="$router.replace({name: 'start'})"
            >
                zurück
            </v-btn>

            <div class="d-flex flex-column align-center mb-4">
                <img
                    :src="training"
                    alt=""
                >
                <p class="text-center">
                    <b>Training</b>
                </p>
            </div>

            <div
                class="d-flex"
                style="width: 100vw"
            >
                <div id="verticalArrow" />

                <div id="boxes">
                    <div
                        class="boxContainer"
                        @click="openInfoBox(boxes[0])"
                    >
                        <p>1. Wo und wie?</p>
                        <img
                            :src="magnifier"
                            alt=""
                        >
                    </div>

                    <div
                        class="boxContainer"
                        @click="openInfoBox(boxes[1])"
                    >
                        <p>2. Bewerbungsunterlagen</p>
                        <img
                            :src="documents"
                            alt=""
                        >
                    </div>

                    <div
                        class="boxContainer"
                        @click="openInfoBox(boxes[2])"
                    >
                        <p>3. Vorstellungsgespräche</p>
                        <img
                            :src="dialog"
                            alt=""
                        >
                    </div>

                    <div
                        class="boxContainer"
                        @click="openInfoBox(boxes[3])"
                    >
                        <p>4. Weitere Tipps</p>
                        <img
                            :src="idea"
                            alt=""
                        >
                    </div>
                </div>
            </div>
        </div>

        <v-dialog
            v-if="infoDialog"
            v-model="infoDialog"
            max-width="750"
        >
            <v-card>
                <v-card-title
                    class="d-flex mb-5"
                    style="background-color: green; color: white; width: 100%"
                >
                    <h2 style="width: 75%">
                        {{ infoBox.name }}
                    </h2>

                    <div
                        style="width: 25%;"
                        class="d-flex justify-end align-self-start"
                    >
                        <v-btn
                            v-if="$route.name !== 'start'"
                            color="red"
                            class="text-none"
                            elevation="0"
                            dark
                            :small="windowWidth < 800"
                            @click="infoDialog = false"
                        >
                            <v-icon
                                v-if="windowWidth < 800"
                                style="transform: rotate(45deg)"
                            >
                                mdi-plus
                            </v-icon>
                            <p v-else>
                                Schließen
                            </p>
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-text>
                    <video
                        style="width: 100%; max-width: 100%"
                        controls
                    >
                        <source
                            :src="testvideo"
                            type="video/mp4"
                        >
                    </video>
                    <p class="headerText mt-4">
                        Tipps und Tricks
                    </p>
                    <p class="infoText">
                        Hier bekommst du hilfreiche Tipps für das Schreiben deiner Bewerbung!
                    </p>
                    <UniversalItemDownload
                        v-for="(file, id) in infoBox.content"
                        :key="id"
                        :file="file"
                    />

                    <p class="headerText mt-5">
                        Top & Flop
                    </p>
                    <p class="infoText">
                        Schaue dir die schlechten Beispiele an - so sollte man keine Bewerbung abgeben!
                    </p>
                    <UniversalItemDownload
                        v-for="(file, id) in infoBox.content"
                        :key="id"
                        :file="file"
                    />
                    <p class="infoText mt-5">
                        Schaue dir diese Beispiele an - so sollte eine Bewerbung aussehen!
                    </p>
                    <UniversalItemDownload
                        v-for="(file, id) in infoBox.content"
                        :key="id"
                        :file="file"
                    />

                    <p class="headerText mt-5">
                        Jetzt du!
                    </p>
                    <p class="infoText">
                        Du hast dir verschiedene Erklärungen zu Thema "Bewerbungsunterlagen" angeschaut. Überprüfe nun dein Wissen und löse die Aufgaben!
                    </p>
                    <UniversalItemDownload
                        v-for="(file, id) in infoBox.content"
                        :key="id"
                        :file="file"
                    />

                    <p class="headerText mt-5">
                        Video und Audio
                    </p>

                    <audio controls>
                        <source
                            :src="testaudio"
                            type="audio/mpeg"
                        >
                    </audio>
                    <p class="infoText">
                        <small>Music: https://www.bensound.com</small>
                    </p>

                    <div v-if="infoBox.content.length > 0">
                        <p class="headerText">
                            Dateien
                        </p>

                        <UniversalItemDownload
                            v-for="(file, id) in infoBox.content"
                            :key="id"
                            :file="file"
                        />
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import training from '@/assets/training.png';
import {mapState} from "vuex";
import testfile from '@/assets/test.pdf';
import testvideo from '@/assets/test.mp4';
import testaudio from '@/assets/bensound-funnysong.mp3';
import magnifier from '@/assets/magnifier.png'
import documents from '@/assets/documents.png'
import dialog from '@/assets/speech.png'
import idea from '@/assets/idea.png'
import downloadIcon from '@/assets/download.png'
import Breadcrumbs from "@/components/Breadcrumbs";
import UniversalItemDownload from "../components/Utils/UniversalItemDownload";

export default {
    name: "Training",
    components: {UniversalItemDownload, Breadcrumbs},
    data() {
        return {
            training,

            infoDialog: false,
            infoBox: [],        // which infobox is currently open
            boxes: [
                // TODO: content should contain files for the infoBoxDialog
                { id: 0, name: '1. Bewerben - aber wo?', content: [ { filename: 'Tipps und Tricks', file: testfile } ] },
                { id: 1, name: '2. Bewerbungsunterlagen', content: [ { filename: 'Tipps und Tricks', file: testfile }] },
                { id: 2, name: '3. Bewerbungsgespräch', content: [ { filename: 'Tipps und Tricks', file: testfile }] },
                { id: 3, name: '4. Gut zu wissen!', content: [ { filename: 'Tipps und Tricks', file: testfile }] },
            ],

            crumbs: [
                {
                    text: 'Bewerbertraining',
                }
            ],

            testvideo,
            testaudio,
            magnifier,
            documents,
            dialog,
            idea,
            downloadIcon,
        }
    },
    computed: {
        ...mapState('util', ['windowWidth']),
    },
    methods: {
        openInfoBox(box) {
            this.infoDialog = true;
            this.infoBox = box;
        },
    },
}
</script>

<style lang="scss" scoped>
p {
    font-size: 1.50em;
}

.headerText {
    font-weight: bold;
    color: black;
    font-size: 20px;
    margin-bottom: 8px !important;
}

.infoText {
    color: black;
    margin-bottom: 8px !important;
}

/* desktop layout */
.background {
    background-color: lightgrey;
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../assets/Training.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
    height: 100%;
}

#content {
    width: 80vw;
    margin: 0 10vw;
    max-height: 60vh;
}

#areaContainer {
    height: 50vh;
    margin-bottom: 5vh;
    gap: calc(15vw / 3);
}

#arrow {
    height: 10vh;
    background-color: green;
    /* generated with https://bennettfeely.com/clippy/ */
    /* if not supported in any browser replace with an image/screenshot */
    clip-path: polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%);
}

.boxContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-end;
    min-width: 20% !important;
    padding: 12px;
    background-color: rgba(255, 255, 255, 0.8);
    cursor: pointer;

    p {
        margin-top: 8px;
        font-weight: bold;
        font-size: x-large;
        word-wrap: break-word;
    }

    img {
        margin: auto;
        max-width: 80%;
    }
}

.boxContainer:hover {
    filter: brightness(0.8);

    img {
        transform: scale(1.25);
    }
}

/* mobile layout */
#verticalArrow {
    width: 10%;
    background-color: green;
    clip-path: polygon(0 95%, 0 0%, 100% 0, 100% 95%, 50% 100%);
}

#boxes {
    width: 90%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10%;
}

@media (max-width: 800px) {
    .boxContainer {
        flex-direction: row;
        justify-content: space-between;

        p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 24px;
            margin-top: 0;
        }

        img {
            max-height: 7vh;
        }
    }
}
</style>
