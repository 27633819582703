const defaultState = {
  offlineMode: false,
  pendingRequests: {},
};

const mutations = {
  setOfflineMode(state, offlineMode) {
    state.offlineMode = offlineMode;
  },
};

const actions = {
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
