const getBackendAddress = () => {
  if(!process.env.VUE_APP_BACKEND_ADDRESS) {
    console.warn("VUE_APP_BACKEND_ADDRESS not defined. Have set up your .env?")
  }
  if(process.env.VUE_APP_BACKEND_ADDRESS === 'PRODUCTION_BACKEND_ADDRESS') {
    return `https://${window.location.host}/backend`
  }
  return process.env.VUE_APP_BACKEND_ADDRESS;
}

export {
  getBackendAddress,
}
