import { checkResponseStatus } from '@/util/check';
import * as backend from '../../api/backend';

const actions = {
  async generateToken ({commit, dispatch}, params) {
    try {
      const res = await backend.generateToken(params);
      await checkResponseStatus(201, res);
      return await res.json();
    }
    catch(err) {
      return err.response.status;
    }
  },

  async validateToken ({commit, dispatch}, params) {
    try {
      const res = await backend.validateToken(params);
      await checkResponseStatus(200, res);
      return res;
    }
    catch(err) {
      return err.response.status;
    }
  },
}

export default {
  namespaced: true,
  actions,
};
