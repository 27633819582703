<template>
    <div id="background">
        <div
            :style="windowWidth < 800 ? 'margin: 2vh 16px 0 16px' : 'margin: 2vh 20vw 4vh 20vw'"
            style="padding-bottom: 100px;"
        >
            <Breadcrumbs :route="crumbs" />

            <!-- review view -->
            <div
                v-if="readReviews"
                class="formContainer pa-4"
            >
                <v-btn
                    class="text-none mb-8"
                    elevation="0"
                    color="red"
                    dark
                    tile
                    @click="readReviews = false"
                >
                    Zum Betrieb zurück
                </v-btn>

                <p
                    style="font-size: 24px"
                    class="d-flex justify-space-between align-start mb-8"
                >
                    <b class="smallHeading">Praktikumsberichte</b>

                    <img
                        :src="documents"
                        style="max-width: 80px; max-height: 80px"
                        alt=""
                    >
                </p>

                <!-- reviews -->
                <div
                    v-for="(review, index) in reviews"
                    :key="index"
                    class="mb-12"
                >
                    <p class="mb-4">
                        <b>{{ index + 1 }}. Bericht</b>
                    </p>

                    <div class="mb-8">
                        <p class="mb-2">
                            <b>Ein Tag im Betrieb...</b>
                        </p>

                        <p
                            class="underlinedText mb-1"
                            style="white-space: pre-line;"
                        >
                            {{ review.description }}
                        </p>
                    </div>

                    <!-- sliders -->
                    <div
                        v-for="slider in review.sliders"
                        :key="slider.name"
                        class="d-flex mb-4"
                    >
                        <p class="sliderText">
                            {{ slider.leftText }}
                        </p>
                        <v-slider
                            :value="slider.value"
                            min="0"
                            max="10"
                            hide-details
                            readonly
                            thumb-label="always"
                            :thumb-size="24"
                            class="mx-2"
                            style="width: 40%"
                        >
                            <template v-slot:thumb-label="{ value }">
                                {{ sliderValues[value] }}
                            </template>
                        </v-slider>
                        <p class="sliderText">
                            {{ slider.rightText }}
                        </p>
                    </div>
                    <div style="display:flex; flex-direction: row; width: 100%">
                        <UniversalItemDownload
                            :file="review.file"
                            style="width: 100%;"
                        />
                    </div>
                </div>
            </div>

            <!-- company view -->
            <div
                v-else
                class="formContainer pa-4"
            >
                <!-- company data -->
                <div
                    v-if="company !== ''"
                    class="d-flex flex-column"
                >
                    <!-- header -->
                    <div class="d-flex justify-space-between">
                        <v-btn
                            v-if="$route.name !== 'start'"
                            class="text-none mr-2"
                            elevation="0"
                            color="red"
                            dark
                            tile
                            @click="$router.go(-1)"
                        >
                            zurück
                        </v-btn>

                        <div class="align-self-center">
                            <p
                                style="font-size: 32px; font-weight: bold; margin-top: -4px; margin-bottom: -4px !important; word-wrap: anywhere"
                            >
                                {{ company.name }}
                            </p>
                        </div>
                    </div>

                    <!-- company logo and gallery -->
                    <CompanyCarousel
                        :company-image-gallery-urls="companyImageGalleryUrls"
                        :company-logo-url="companyLogoUrl"
                        :preview-mode="false"
                        class="mb-4"
                    />

                    <div class="infoContainer d-flex flex-column">
                        <p>
                            Dieser Betrieb ist in folgendem Bereich tätig:
                            <b>{{ company.category.name }}</b>
                        </p>

                        <p
                            class="mr-2"
                        >
                            Dieser Betrieb bildet aus:
                            <b>{{ company.apprenticeYes ? 'Ja' : 'Nein' }}</b>
                        </p>

                        <p>
                            Freie Stellen: <b>{{ company.openPositions }}</b>
                        </p>
                    </div>

                    <div
                        class="infoContainer"
                        style="width: 50%"
                    >
                        <p class="mb-4 smallHeading">
                            <b>Ansprechpartner</b>
                        </p>

                        <p
                            class="underlinedText"
                            style="white-space: pre-line;"
                        >
                            {{ company.contactName }}
                        </p>
                    </div>

                    <div
                        class="infoContainer"
                        style="width: 50%"
                    >
                        <p class="mb-4 smallHeading">
                            <b>Adresse</b>
                        </p>

                        <p
                            class="underlinedText"
                            style="white-space: pre-line;"
                        >
                            {{ company.contactAdress }}
                        </p>
                    </div>

                    <div
                        class="infoContainer"
                        style="width: 50%"
                    >
                        <p class="mb-4 smallHeading">
                            <b>Kontakt</b>
                        </p>

                        <p
                            class="underlinedText"
                            style="white-space: pre-line;"
                        >
                            {{ company.contactEmail }}
                        </p>
                    </div>

                    <div
                        class="infoContainer"
                        style="width: 50%"
                    >
                        <p class="mb-4 smallHeading">
                            <b>Website</b>
                        </p>

                        <p
                            class="underlinedText"
                            style="white-space: pre-line;"
                        >
                            {{ company.contactWebsite }}
                        </p>
                    </div>

                    <div class="infoContainer">
                        <p class="mb-4 smallHeading">
                            <b>Was macht dieser Betrieb?</b>
                        </p>

                        <!-- Dont try this at home kids -->
                        <p
                            class="underlinedText"
                            style="white-space: pre-line;"
                            v-html="urlify(company.description)"
                        />
                        <!--                            {{ urlify(company.description) }}-->
                    </div>

                    <div class="mb-4">
                        <div
                            v-if="reviews.length > 0"
                        >
                            <div class="d-flex justify-space-between align-start">
                                <p class="smallHeading">
                                    <b>Hier findest du Praktikumsberichte:</b>
                                </p>
                                <img
                                    :src="documents"
                                    style="max-width: 80px; max-height: 80px"
                                    alt=""
                                >
                            </div>

                            <v-btn
                                elevation="0"
                                tile
                                color="blue"
                                class="text-none mt-2"
                                style="color: white;"
                                x-large
                                @click="readReviews = true"
                            >
                                Berichte lesen
                            </v-btn>
                        </div>

                        <p
                            v-else
                            class="smallHeading"
                        >
                            <b>Für diesen Betrieb gibt es noch keine Praktikumsberichte</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import * as backend from '../api/backend.js';
import Breadcrumbs from "@/components/Breadcrumbs";
import CompanyCarousel from "../components/CompanyCarousel";
import UniversalItemDownload from "../components/Utils/UniversalItemDownload";
import documents from '@/assets/documents.png'

export default {
    name: "Company",
    components: {UniversalItemDownload, Breadcrumbs, CompanyCarousel },
    data() {
        return {
            companyId: '',
            company: '',
            companyLogoUrl: '',
            companyImageGalleryUrls: [],
            reviews: [],

            readReviews: false,         // if true the page changes to a list of available reviews

            // mock slider data
            // sliders: [
            //     { name: 'environment', value: '5', leftText: 'Im Freien arbeiten', rightText: 'Drinnen arbeiten' },
            //     { name: 'physical', value: '6', leftText: 'körperlich anstrengend', rightText: 'nicht körperlich anstrengend' },
            //     { name: 'stance', value: '5', leftText: 'Arbeiten im Stehen', rightText: 'Arbeiten im Sitzen' },
            //     { name: 'contact', value: '7', leftText: 'Kontakt mit Kunden', rightText: 'kein Kontakt mit Kunden' },
            //     { name: 'time', value: '3', leftText: 'Wochenende und Schichtdienst', rightText: 'regelmäßige Arbeitszeiten' },
            //     { name: 'variety', value: '5', leftText: 'viel Abwechslung, oft was Neues', rightText: 'routinemäßiges Arbeiten' },
            //     { name: 'machine', value: '0', leftText: 'Arbeit mit Maschinen', rightText: 'nicht mit Maschinen arbeiten' },
            // ],

            // this just maps the slider values from 0-10 to 5 to 0 to 5
            sliderValues: ['5', '4', '3', '2', '1', '0', '1', '2', '3', '4', '5'],

            crumbs: [
                {
                    text: 'Betrieb',
                }
            ],

            documents,
        }
    },
    computed: {
        ...mapState('util', ['windowWidth']),
    },
    created() {
        this.companyId = this.$route.query.companyId;
    },
    async mounted() {
        this.setCompany();
        this.getReviews();
    },
    async unmounted() {
        URL.revokeObjectURL(this.companyLogoUrl);
        for (const imageUrl in this.companyImageGalleryUrls) {
            URL.revokeObjectURL(imageUrl);
        }
    },
    methods: {
        ...mapActions('trainee', ['getTraineesOfCompany']),
        ...mapActions('company', ['getCompany']),

        async setCompany() {
            const company = await this.getCompany(this.companyId);
            this.company = company;
            if (company.logo) {
                this.loadCompanyLogo(company.logo);
            }
            // Load image one after another
            company.files.reduce(async (accPromise, fileId) => {
                await accPromise;
                await this.loadCompanyImageGalleryImage(fileId);
            }, Promise.resolve());
        },
        async loadCompanyLogo(fileId) {
            const companyImageResponse = await backend.getFile(fileId);
            const companyImageBlob = await companyImageResponse.blob();
            this.companyLogoUrl = URL.createObjectURL(companyImageBlob);
        },
        async loadCompanyImageGalleryImage(fileId) {
            const imageResponse = await backend.getFile(fileId);
            const imageBlob = await imageResponse.blob();
            const imageUrl = URL.createObjectURL(imageBlob);
            this.companyImageGalleryUrls.push(imageUrl);
        },

        async getReviews() {
            const reviewEntries = await this.getTraineesOfCompany(this.companyId);
            this.reviews = await reviewEntries.reduce(async (previousPromise, current) => {
                const previous = await previousPromise;

                const fileFromBackend = await backend.getFile(current.file);
                const fetchedFile = await fileFromBackend.blob();
                const file = new File([fetchedFile], 'Bericht.pdf');
                current.file = file;

                previous.push(current);
                return previous;
            }, Promise.resolve([]));
        },

        async downloadFile(review) {
            console.log('review', review)
            let res = await backend.getFile(review.file);
            let blob = await res.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = 'Bericht';
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            setTimeout(function () {
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        },

        urlify(text) {
            // eslint-disable-next-line no-useless-escape
            var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|software|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
            return text.toString().replace(urlRegex, function (url) {
                url = url.includes('http') ? url : 'http://' + url;
                return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
            })
            // or alternatively
            // return text.replace(urlRegex, '<a href="$1">$1</a>')
        },
    }
}
</script>

<style scoped>

.v-text-field {
    margin-top: 0 !important;
}

/* moves the delimiters (dots) to the left */
.v-carousel__controls {
    justify-content: start !important;
}

#background {
    background-color: lightgrey;
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../assets/Praktikum.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
}

.formContainer {
    border: 1px solid black;
    background-color: white;
    width: 100%;
}

.infoContainer {
    margin-bottom: 32px;
}

.underlinedText {
    display: block;
    border-bottom: 1px solid grey;
    font-size: 20px;
}

.sliderText {
    width: 30%;
    font-size: 20px;
}

.smallHeading {
    font-size: 24px;
}
</style>
