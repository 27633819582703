<template>
    <div id="background">
        <div
            :style="tokenValid ? '' : 'display: none'"
            style="padding-bottom: 100px;"
        >
            <div
                class="d-flex flex-column align-center justify-center"
                :style="windowWidth < 800 ? 'margin: 2vh 16px 0 16px' : 'margin: 2vh 20vw 2vh 20vw'"
            >
                <div class="formContainer pa-4">
                    <div
                        class="d-flex flex-column justify-start"
                        style="width: 100%"
                    >
                        <!-- header -->
                        <div class="d-flex justify-end">
                            <div class="align-self-center">
                                <p
                                    style="font-size: 32px; font-weight: bold; margin-top: -4px; margin-bottom: -4px !important; word-wrap: anywhere"
                                >
                                    {{ company.name }}
                                </p>
                            </div>
                        </div>

                        <!-- company logo and gallery -->
                        <CompanyCarousel
                            :company-image-gallery-urls="companyImageGalleryUrls"
                            :company-logo-url="companyLogoUrl"
                            :preview-mode="false"
                            class="mb-4"
                        />

                        <div class="mb-8">
                            <p class="mb-2">
                                <b>Ein Tag im Betrieb...</b>
                            </p>

                            <p style="font-size: 0.75em">
                                Beschreibe einen Tag im Betrieb, wann fängt er an, wie lange geht er.
                                Was hast du im Betrieb gelernt und was hat dir besonders gefallen?
                            </p>

                            <v-textarea
                                v-model="description"
                                style="font-size: 20px;"
                                rows="1"
                                no-resize
                                auto-grow
                                hide-details
                            />
                        </div>
                    </div>

                    <!-- sliders -->
                    <div style="width: 100%">
                        <p class="mb-4">
                            <b>Mein Praktikum bewertet:</b>
                        </p>

                        <div
                            v-for="slider in sliders"
                            :key="slider.name"
                            class="d-flex mb-4"
                        >
                            <p
                                class="sliderText"
                            >
                                {{ slider.leftText }}
                            </p>
                            <v-slider
                                v-model="slider.value"
                                min="0"
                                max="10"
                                thumb-label="always"
                                :thumb-size="24"
                                hide-details
                                class="mx-4"
                                style="width: 40%"
                            >
                                <template v-slot:thumb-label="{ value }">
                                    {{ sliderValues[value] }}
                                </template>
                            </v-slider>
                            <p
                                class="sliderText"
                            >
                                {{ slider.rightText }}
                            </p>
                        </div>
                    </div>

                    <div class="d-flex justify-space-between align-start infoContainer my-4">
                        <span class="mr-2">
                            <b>Bericht als PDF hochladen:</b>
                        </span>
                        <img
                            :src="documents"
                            style="max-width: 60px; margin: 5px; cursor: pointer"
                            alt=""
                            @click="() => { $refs.reportFile.click() }"
                        >
                        <input
                            id="report"
                            ref="reportFile"
                            type="file"
                            accept="application/pdf"
                            hidden
                        >
                    </div>

                    <v-btn
                        elevation="0"
                        tile
                        color="blue"
                        class="text-none mt-2"
                        style="color: white;"
                        x-large
                        @click="() => { $refs.reportFile.click() }"
                    >
                        Bericht hochladen
                    </v-btn>

                    <div
                        id="actionButtons"
                        class="d-flex justify-end align-center"
                    >
                        <v-btn
                            class="text-none mt-4 mr-4 align-self-end"
                            color="red"
                            elevation="0"
                            tile
                            dark
                            @click="goToStart"
                        >
                            Zur Startseite
                        </v-btn>
                        <v-btn
                            class="text-none mt-4 align-self-end"
                            color="green"
                            elevation="0"
                            tile
                            dark
                            @click="saveReport"
                        >
                            Speichern
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import CompanyCarousel from "@/components/CompanyCarousel";
import documents from '@/assets/documents.png';
import * as backend from "@/api/backend";

export default {
    name: "TraineeForm",
    components: { CompanyCarousel },
    data() {
        return {
            // meta
            tokenValid : false,
            token: null,
            createNew: true,
            traineeId: null,

            companyLogoUrl: '',
            companyImageGalleryUrls: [],

            // data
            availableCompanies: [],

            sliders: [
                { name: 'environment', value: '5', leftText: 'Im Freien arbeiten', rightText: 'Drinnen arbeiten' },
                { name: 'physical', value: '5', leftText: 'körperlich anstrengend', rightText: 'nicht körperlich anstrengend' },
                { name: 'stance', value: '5', leftText: 'Arbeiten im Stehen', rightText: 'Arbeiten im Sitzen' },
                { name: 'contact', value: '5', leftText: 'Kontakt mit Kunden', rightText: 'kein Kontakt mit Kunden' },
                { name: 'time', value: '5', leftText: 'Wochenende und Schichtdienst', rightText: 'regelmäßige Arbeitszeiten' },
                { name: 'variety', value: '5', leftText: 'viel Abwechslung, oft was Neues', rightText: 'routinemäßiges Arbeiten' },
                { name: 'machine', value: '5', leftText: 'Arbeit mit Maschinen', rightText: 'nicht mit Maschinen arbeiten' },
            ],

            // this just maps the slider values from 0-10 to 5 to 0 to 5
            sliderValues: ['5', '4', '3', '2', '1', '0', '1', '2', '3', '4', '5'],

            company: null,
            description: '',
            documents,
        }
    },
    computed: {
        ...mapState('util', ['windowWidth']),
    },
    async mounted() {
        this.availableCompanies = await this.getAllCompanies();

        if (window.location.search.length > 0) {
            const tokenResponse = await this.validateToken(window.location.search.substr(1));
            if (tokenResponse.status === 200) {
                const body = await tokenResponse.json();
                this.token = window.location.search.substr(1);
                if (body !== null) {
                    this.availableCompanies.forEach((el)=>{
                        // console.log(el._id, body.companyRef);
                        if (el._id === body.company) {
                            this.company = el;
                        }
                        if (el._id === body.companyRef) {
                            this.company = el;
                        }
                    });
                    // TODO: test and debug this
                    if(Object.keys(body).length>1) {
                        this.description = body.description;
                        this.sliders = body.sliders;
                        this.traineeId = body._id;
                        // use patch route
                        this.createNew = false;
                    } else {
                        // use post route
                        this.createNew = true;
                    }
                } else {
                    // use post route
                    this.createNew = true;
                }
                // console.log(this.traineeId);
                // enable Form
                this.tokenValid = true;
            } else {
                window.alert('Der Link ist ungültig');
            }
        } else {
            window.alert('Der Link ist ungültig');
        }

        await this.setCompany();
    },
    methods: {
        ...mapActions('company', ['getAllCompanies', 'getCompany']),
        ...mapActions('trainee', ['createTrainee', 'updateTrainee', 'uploadFileTrainee']),
        ...mapActions('token', ['validateToken']),
        ...mapMutations('snackbar', ['showSnackbar']),

        goToStart() {
            this.$router.push({ name: 'start' });
        },

        async saveReport() {
            let body = {
                company: this.company._id,
                description: this.description,
                sliders: this.sliders,
                token: this.token,
                traineeId: this.traineeId,
            }
            // console.log(this.sliders);
            // console.log(body);
            let res;
            if (this.createNew) {
                res = await this.createTrainee(body);
                // click w/o reload uses patch next
                this.createNew = false;
            } else {
                res = await this.updateTrainee(body);
            }
            console.log(res);
            if(this.$refs.reportFile.files[0]) {
                let file = this.$refs.reportFile.files[0];
                file.traineeId = res._id;
                // console.log(file);
                let resFile = await this.uploadFileTrainee(file);
                if (resFile.status === 201) {
                    window.alert('Der Bericht wurde erfolgreich gespeichert!');
                    window.location.href = "/";
                }
            } else {
                window.alert('Der Bericht wurde erfolgreich gespeichert!');
                window.location.href = "/";
            }
        },

        async setCompany() {
            const company = await this.getCompany(this.company._id);
            this.company = company;
            if (company.logo) {
                this.loadCompanyLogo(company.logo);
            }
            // Load image one after another
            company.files.reduce(async (accPromise, fileId) => {
                await accPromise;
                await this.loadCompanyImageGalleryImage(fileId);
            }, Promise.resolve());
        },
        async loadCompanyLogo(fileId) {
            const companyImageResponse = await backend.getFile(fileId);
            const companyImageBlob = await companyImageResponse.blob();
            this.companyLogoUrl = URL.createObjectURL(companyImageBlob);
        },
        async loadCompanyImageGalleryImage(fileId) {
            const imageResponse = await backend.getFile(fileId);
            const imageBlob = await imageResponse.blob();
            const imageUrl = URL.createObjectURL(imageBlob);
            this.companyImageGalleryUrls.push(imageUrl);
        },
    },
}
</script>

<style lang="scss" scoped>
#background {
    background-color: lightgrey;
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../assets/Praktikum.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
}

.formContainer {
    border: 1px solid black;
    background-color: white;
    width: 100%;
    font-size: 1.5em;
}

.sliderText {
    width: 30%;
    font-size: 20px;
}

.noTopSpacing {
    padding-top: 0;
    margin-top: 0;
}

.textFieldContainer {
    display: flex;
    align-content: center;
    margin-bottom: 8px;
}
</style>
