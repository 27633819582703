<template>
    <div>
        <div
            v-if="(companyLogoUrl || companyImageGalleryUrls.length > 0)"
            style="position: relative; margin-bottom: 2%"
        >
            <v-carousel
                v-if="companyImageGalleryUrls.length > 0"
                :show-arrows="false"
                :hide-delimiter-background="true"
                class="text-center my-4"
                height="400"
                style="width: 100%;"
                cycle
                light
            >
                <v-carousel-item
                    v-for="imageUrl in companyImageGalleryUrls"
                    :key="imageUrl"
                >
                    <img
                        :src="imageUrl"
                        style="width: 100%; height: 100%; object-fit: cover; object-position: center"
                        alt=""
                        @click="previewMode ? $emit('uploadGallery') : {}"
                    >
                </v-carousel-item>
            </v-carousel>

            <img
                v-if="companyLogoUrl"
                id="companyLogo"
                class="pa-1"
                :src="companyLogoUrl"
                alt="Firmenlogo"
                @click="previewMode ? $emit('uploadLogo') : {}"
            >
        </div>
        <div
            v-else
            class="mb-4"
        />
    </div>
</template>

<script>
export default {
    name: "CompanyCarousel",

    props: {
        companyImageGalleryUrls: { type: Array, required: false },
        companyLogoUrl: { type: String, required: false },
        previewMode: { type: Boolean, required: false },
    },
}
</script>

<style scoped>
    #companyLogo {
        position: absolute;
        bottom: -5%;
        right: 3%;
        max-width: 200px;
        border: 1px solid black;
        background-color: white;
    }
</style>
