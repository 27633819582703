import * as backend from "../../api/backend";

const defaultState = {
    loading: false,
    windowWidth: 0,
    windowHeight: 0,
};

const mutations = {
    setLoadingShown(state, loading) {
        state.loading = loading;
    },
    setWindowWidth(state, value) {
        state.windowWidth = value;
    },
    setWindowHeight(state, value) {
        state.windowHeight = value;
    },
};

const getters = {
    isLoadingShown: (state) => state.loading,
};

const actions = {
    toggleLoading({ commit }, loading) {
        commit("setLoadingShown", loading);
    },
    setWindowWidth({commit}, value) {
        commit('setWindowWidth', value);
    },
    setWindowHeight({commit}, value) {
        commit('setWindowHeight', value);
    },
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    getters,
    actions,
};
