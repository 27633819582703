import { render, staticRenderFns } from "./TraineeForm.vue?vue&type=template&id=57d7ed05&scoped=true&"
import script from "./TraineeForm.vue?vue&type=script&lang=js&"
export * from "./TraineeForm.vue?vue&type=script&lang=js&"
import style0 from "./TraineeForm.vue?vue&type=style&index=0&id=57d7ed05&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57d7ed05",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VSlider,VTextarea})
