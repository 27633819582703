import { checkResponseStatus } from '@/util/check';
import * as backend from '../../api/backend';

const actions = {
  async createCompany ({commit, dispatch}, params) {
    try {
      const res = await backend.createCompany(params);
      await checkResponseStatus(201, res);
      return await res.json();
    }
    catch(err) {
      return err.response.status;
    }
  },
  async updateCompany ({commit, dispatch}, params) {
    try {
      const companyId = params.companyId;
      delete params.companyId;
      const res = await backend.updateCompany(companyId, params);
      await checkResponseStatus(201, res);
      return await res.json();
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getCompany ({commit, dispatch}, params) {
    try {
      const res = await backend.getCompany(params);
      await checkResponseStatus(200, res);
      return await res.json();
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getAllCompanies ({commit, dispatch}) {
    try {
      const res = await backend.getAllCompanies();
      await checkResponseStatus(200, res);
      return await res.json();
    }
    catch(err) {
      return err.response.status;
    }
  },

  async uploadLogoCompany ({ commit, dispatch}, params) {
    try {
        const companyId = params.companyId;
        delete params.companyId;
        const res = await backend.uploadLogoCompany(companyId, params);
        await checkResponseStatus(201, res);
        return await res.json();
    }
    catch(err) {
        return err.response.status;
    }
  },

  async uploadSlideshowCompany ({ commit, dispatch}, params) {
    try {
        const companyId = params.companyId;
        delete params.companyId;
        const res = await backend.uploadSlideshowCompany(companyId, params);
        await checkResponseStatus(201, res);
        return await res.json();
    }
    catch(err) {
        return err.response.status;
    }
  },

  async deleteSlideshowCompany ({ commit, dispatch}, params) {
    try {
        const res = await backend.deleteSlideshowCompany(params);
        await checkResponseStatus(200, res);
        return await res.json();
    }
    catch(err) {
        return err.response.status;
    }
  },
}

export default {
  namespaced: true,
  actions,
};
