import { checkResponseStatus } from '@/util/check';
import * as backend from '../../api/backend';

const actions = {
    async createTrainee({commit, dispatch}, params) {
        try {
            const res = await backend.createTrainee(params);
            await checkResponseStatus(201, res);
            return await res.json();
        } catch (err) {
            return err.response.status;
        }
    },
    async updateTrainee({commit, dispatch}, params) {
        try {
            const traineeId = params.traineeId;
            delete params.traineeId;
            const res = await backend.updateTrainee(traineeId, params);
            await checkResponseStatus(201, res);
            return await res.json();
        } catch (err) {
            return err.response.status;
        }
    },

  // get all reviews for company
  async getTraineesOfCompany ({commit, dispatch}, params) {
    try {
      const res = await backend.getTraineesOfCompany(params);
      await checkResponseStatus(200, res);
      return await res.json();
    }
    catch(err) {
      return err.response.status;
    }
  },

  async uploadFileTrainee ({ commit, dispatch}, params) {
    try {
        const traineeId = params.traineeId;
        delete params.traineeId;
        const res = await backend.uploadFileTrainee(traineeId, params);
        await checkResponseStatus(201, res);
        return res;
      }
      catch(err) {
        return err.response.status;
      }
    },
}

export default {
    namespaced: true,
    actions,
};
