<template>
    <div id="background">
        <div
            :style="tokenValid ? '' : 'display: none'"
            style="padding-bottom: 100px;"
        >
            <div
                class="d-flex flex-column align-center"
                :style="windowWidth < 800 ? 'margin: 2vh 16px 0 16px' : 'margin: 2vh 20vw 2vh 20vw'"
            >
                <div class="formContainer pa-4">
                    <p
                        style="font-size: 24px"
                        class="mb-8"
                    >
                        <b>Ihren Betrieb anlegen</b>
                    </p>

                    <div
                        class="d-flex flex-column"
                        style="width: 100%"
                    >
                        <div
                            class="infoContainer"
                        >
                            <v-text-field
                                v-model="companyName"
                                placeholder="Name Ihres Betriebs"
                                style="font-size: 24px"
                                hide-details
                            />
                        </div>

                        <div
                            class="infoContainer"
                            style="font-size: 16px"
                        >
                            <p style="font-size: 20px; color: red">
                                <b>Notiz:</b> Auswahl mehrer Bilder möglich. Bilder ändern durch erneutes Klicken.
                            </p>
                            <CompanyCarousel
                                :company-image-gallery-urls="companyImageGalleryUrlComputed"
                                :company-logo-url="companyLogoUrlComputed"
                                :preview-mode="true"

                                @uploadGallery="uploadGallery"
                                @uploadLogo="uploadLogo"
                            />
                        </div>

                        <!-- Only here to simulate clicks on them -->
                        <input
                            id="gallery"
                            ref="galleryInput"
                            class="visually-hidden"
                            type="file"
                            accept="image/*"
                            multiple
                            @change="setInputToGallery"
                        >
                        <input
                            id="logo"
                            ref="logoInput"
                            class="visually-hidden"
                            type="file"
                            accept="image/*"
                            @change="setInputToLogo"
                        >

                        <div class="infoContainer d-flex flex-column justify-space-between">
                            <div class="d-flex align-center">
                                <p
                                    class="mr-2"
                                    style="font-size: 20px"
                                >
                                    Dieser Betrieb bildet aus:
                                </p>
                                <v-checkbox
                                    v-model="apprenticeYes"
                                    class="checkbox"
                                    hide-details
                                    label="Ja"
                                />
                                <v-checkbox
                                    v-model="apprenticeNo"
                                    class="checkbox ml-4"
                                    hide-details
                                    label="Nein"
                                    @click="apprenticeYes = !apprenticeYes"
                                />
                            </div>

                            <div class="d-flex align-center">
                                <p
                                    style="font-size: 20px"
                                    class="mr-2"
                                >
                                    Freie Stellen:
                                </p>

                                <div style="width: 3em">
                                    <v-text-field
                                        v-model.number="openPositions"
                                        type="number"
                                        class="pt-0"
                                        hide-details
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="infoContainer d-flex align-center">
                            <p
                                class="mr-2"
                                style="font-size: 20px"
                            >
                                Bereich auswählen:
                            </p>

                            <div style="width: fit-content">
                                <v-select
                                    v-model="category"
                                    :items="availableCategories"
                                    item-text="name"
                                    item-value="id"
                                    no-data-text="Bereiche konnten nicht geladen werden"
                                    class="pt-0"
                                    hide-details
                                    return-object
                                />
                            </div>
                        </div>

                        <div
                            class="infoContainer"
                            style="width: 50%"
                        >
                            <p><b>Ansprechpartner</b></p>

                            <v-text-field
                                v-model="contactPersonName"
                                placeholder="Name"
                                style="font-size: 20px"
                                hide-details
                            />
                            <v-text-field
                                v-model="contactPersonAdress"
                                placeholder="Adresse"
                                style="font-size: 20px"
                                hide-details
                            />
                            <v-text-field
                                v-model="contactPersonContact"
                                placeholder="E-Mail/Telefon"
                                style="font-size: 20px"
                                hide-details
                            />
                            <v-text-field
                                v-model="contactPersonWebsite"
                                placeholder="Website"
                                style="font-size: 20px"
                                hide-details
                            />
                        </div>

                        <div class="infoContainer">
                            <p><b>Was macht dieser Betrieb?</b></p>

                            <v-textarea
                                v-model="description"
                                name="Beschreibung"
                                style="font-size: 20px"
                                rows="1"
                                no-resize
                                auto-grow
                                hide-details
                            />
                        </div>
                    </div>

                    <div class="d-flex justify-end mt-4">
                        <v-btn
                            class="text-none mr-4"
                            color="red"
                            elevation="0"
                            tile
                            dark
                            @click="$router.replace('start')"
                        >
                            Zur Startseite
                        </v-btn>

                        <v-btn
                            class="text-none"
                            color="green"
                            elevation="0"
                            tile
                            dark
                            @click="save"
                        >
                            Speichern
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import CompanyCarousel from "../components/CompanyCarousel";
import ExampleUpload from "../assets/GalleryUpload.png"
import LogoUpload from "../assets/LogoUpload.png"
import * as backend from "../api/backend";

export default {
    name: "CompanyForm",
    components: { CompanyCarousel },
    data() {
        return {
            // meta
            tokenValid : false,
            token: null,
            createNew: true,
            companyId: null,

            // data
            companyName: '',
            apprenticeYes: false,
            openPositions: '',
            contactPersonName: '',
            contactPersonContact: '',
            contactPersonAdress: '',
            contactPersonWebsite: '',
            description: '',
            category: '',

            availableCategories: [],             // available categories

            companyImageGalleryUrls: [],
            companyLogoUrl: null,

            // TODO: validation
            fields: {
                name: false,
                positions: false,
                contactName: false,
                contactContact: false,
                contactAddress: false,
                contactWebsite: false,
            },

            LogoUpload,
            ExampleUpload,
        }
    },
    computed: {
        ...mapState('util', ['windowWidth']),

        apprenticeNo() {
            return !this.apprenticeYes;
        },
        companyLogoUrlComputed() {
            if(this.companyLogoUrl) {
                return this.companyLogoUrl;
            } else {
                return LogoUpload;
            }
        },
        companyImageGalleryUrlComputed() {
            if(this.companyImageGalleryUrls.length > 0) {
                return this.companyImageGalleryUrls;
            } else {
                return [this.ExampleUpload];
            }
        }

    },
    async mounted() {
        this.availableCategories = await this.getCategories();
        if (window.location.search.length > 0) {
            const tokenResponse = await this.validateToken(window.location.search.substr(1));
            if (tokenResponse.status === 200) {
                const body = await tokenResponse.json();
                console.log('Mounted: ', body);
                this.token = window.location.search.substr(1);
                if (body !== null) {
                    this.companyName = body.name;
                    this.apprenticeYes = body.apprenticeYes;
                    this.openPositions = body.openPositions;
                    this.contactPersonName = body.contactName;
                    this.contactPersonContact = body.contactEmail;
                    this.contactPersonAdress = body.contactAdress;
                    this.contactPersonWebsite = body.contactWebsite;
                    this.description = body.description;
                    this.availableCategories.forEach((el) => {
                        if (el._id === body.category) {
                            this.category = el;
                        }
                    });

                    if (body.logo) {
                        this.loadCompanyLogo(body.logo);
                    }
                    // Load image one after another
                    body.files.reduce(async (accPromise, fileId) => {
                        await accPromise;
                        await this.loadCompanyImageGalleryImage(fileId);
                    }, Promise.resolve());

                    // this.category = body.category;
                    this.companyId = body._id;
                    // use patch route
                    this.createNew = false;
                } else {
                    // use post route
                    this.createNew = true;
                }
                // enable Form
                this.tokenValid = true;
            } else {
                window.alert('Der Link ist ungültig');
            }
        } else {
            window.alert('Der Link ist ungültig');
        }
    },
    methods: {
        ...mapActions('company', ['createCompany', 'updateCompany', 'uploadLogoCompany', 'uploadSlideshowCompany', 'deleteSlideshowCompany']),
        ...mapActions('category', ['getCategories']),
        ...mapActions('token', ['validateToken']),

        async loadCompanyLogo(fileId) {
            const companyImageResponse = await backend.getFile(fileId);
            const companyImageBlob = await companyImageResponse.blob();
            this.companyLogoUrl = URL.createObjectURL(companyImageBlob);
        },
        async loadCompanyImageGalleryImage(fileId) {
            const imageResponse = await backend.getFile(fileId);
            const imageBlob = await imageResponse.blob();
            const imageUrl = URL.createObjectURL(imageBlob);
            this.companyImageGalleryUrls.push(imageUrl);
        },

        uploadLogo() {
            document.getElementById('logo').click();
        },

        uploadGallery() {
            document.getElementById('gallery').click();
        },

        setInputToLogo() {
            this.companyLogoUrl = null;
            const [file] = this.$refs.logoInput.files;
            if(file) {
                this.companyLogoUrl = URL.createObjectURL(file);
            }
        },

        setInputToGallery () {
            this.companyImageGalleryUrls = [];
            let files = this.$refs.galleryInput.files;
            if (files && files.length > 0) {
                for(let i=0; i<files.length; i++) {
                    this.companyImageGalleryUrls.push(URL.createObjectURL(files[i]));
                }
            }
        },

        async save() {
            let body = {
                name: this.companyName,
                openPositions: this.openPositions,
                apprenticeYes: this.apprenticeYes,
                contactName: this.contactPersonName,
                contactEmail: this.contactPersonContact,
                contactAdress: this.contactPersonAdress,
                contactWebsite: this.contactPersonWebsite,
                description: this.description,
                category: this.category._id,
                token: this.token,
                companyId: this.companyId,
            };
            let res;
            if (this.createNew) {
                res = await this.createCompany(body);
                // same click on page now updates
                this.createNew = false;
            } else {
                res = await this.updateCompany(body);
            }

            let file = this.$refs.logoInput.files[0];
            if (file) {
                file.companyId = res._id;
                await this.uploadLogoCompany(file);
            }
            let files = this.$refs.galleryInput.files;
            if (files.length > 0) {
                await this.deleteSlideshowCompany(res._id);
            }
            for(let i=0; i<files.length; i++) {
                let el = files[i];
                el.companyId = res._id;
                await this.uploadSlideshowCompany(el);
            }
            window.alert('Die Informationen wurden erfolgreich gespeichert!');
            window.location.href = "/";
        },
    },
}
</script>

<style scoped>
#background {
    background-color: lightgrey;
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../assets/Praktikum.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
}

.formContainer {
    border: 1px solid black;
    background-color: white;
    width: 100%;
}

.v-text-field {
    margin-top: 0 !important;
}

.infoContainer {
    margin-bottom: 40px;
}

.checkbox {
    margin-top: 0 !important;
    padding-top: 0;
}
</style>
