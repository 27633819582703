import {
    deleteRequest,
    filePostRequest,
    getRequest,
    patchRequest,
    postRequest
} from "./request";

const createCompany = async (body) => postRequest('/company/create', body);
const getCompany = async (companyId) => getRequest(`/company/${companyId}`);
const getCompanyImage = async (companyId) => getRequest(`/company/${companyId}/image`); // /files/:fileId
const getAllCompanies = async () => getRequest('/company');
const updateCompany = async (companyId, body) => patchRequest(`/company/${companyId}`, body);
const uploadLogoCompany = async (companyId, file) => filePostRequest(`/company/logo/${companyId}`, file);
const uploadSlideshowCompany = async (companyId, file) => filePostRequest(`/company/files/${companyId}`, file);
const deleteSlideshowCompany = async (companyId) => deleteRequest(`/company/files/${companyId}`);

const getTraineesOfCompany = async (companyId) => getRequest(`/trainee/${companyId}`);
const createTrainee = async (body) => postRequest('/trainee/create', body);
const updateTrainee = async (traineeId, body) => patchRequest(`/trainee/${traineeId}`, body);
const uploadFileTrainee = async (traineeId, file) => filePostRequest(`/trainee/upload/${traineeId}`, file);

const generateToken = async (body) => postRequest('/token/generate', body);
const validateToken = async (token) => postRequest(`/token/validate/${token}`);
const getTokenAll = async () => getRequest('/token');
const deleteToken = async (tokenId, body) => deleteRequest(`/token/${tokenId}`, body);
const validateAdminPassword = async (token) => getRequest(`/token/admin/${token}`);
const validateAdminToken = async (token) => getRequest(`/token/admin/validate/${token}`);
const validateCompany = async (token) => getRequest(`/token/validateCompany/${token}`);

const getFile = async (fileId) => getRequest(`/file/${fileId}`);

const getCategories = async (categoryId) => getRequest('/category/:categoryId');
const getCategory = async () => getRequest('/category');
const postCategory = async (body) => postRequest('/category', body);
const uploadLogoCategory = async (categoryId, file) => filePostRequest(`/category/file/${categoryId}`, file);

const getHelloFromBackend = async () => getRequest(`/`);

export {
    getHelloFromBackend,

    createCompany,
    getCompany,
    getCompanyImage,
    getAllCompanies,
    updateCompany,
    uploadLogoCompany,
    uploadSlideshowCompany,
    deleteSlideshowCompany,

    getTraineesOfCompany,
    createTrainee,
    updateTrainee,
    uploadFileTrainee,

    generateToken,
    validateToken,
    getTokenAll,
    deleteToken,
    validateAdminPassword,
    validateAdminToken,
    validateCompany,

    getCategories,
    getCategory,
    postCategory,
    uploadLogoCategory,

    getFile,
};

