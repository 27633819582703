<template>
    <div
        class="d-flex justify-space-between align-center downloadContainer"
        @click="download"
    >
        <p>{{ file.filename || file.name }}</p>

        <img
            :src="downloadIcon"
            class="icon"
            alt="download"
        >
    </div>
</template>

<script>
import downloadIcon from '@/assets/download.png'

export default {
    name: 'UniversalItemDownload',
    props: {
        file: { required: true },
    },
    data: () => ({
        downloadIcon,
    }),
    methods: {
        download() {
            console.log('download');
            const a = document.createElement("a");
            const url = window.URL.createObjectURL(this.file);
            a.href = this.file.file || url;
            a.download = this.file.name || this.file.filename;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            setTimeout(function(){
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
            console.log('download end');
        },
    }
}
</script>

<style scoped lang="scss">
.downloadContainer {
    p {
        font-size: 1.5em;
        color: #618DC6 !important;
    }
}
.downloadContainer:hover {
    cursor: pointer;
    filter: brightness(0.8);
    text-decoration: underline;
}
</style>
