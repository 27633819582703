import Admin from "@/views/Admin";
import CompanyForm from "@/views/CompanyForm";
import Home from "@/views/Home";
import Impressum from "@/views/Impressum";
import TraineeForm from "@/views/TraineeForm";
import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import Version from './components/Version';
import vuetify from './plugins/vuetify';
import store from './store';
import Apprentice from './views/Apprentice';
import Company from './views/Company';
import Intern from './views/Intern';
import Training from './views/Training';

Vue.config.productionTip = false;
Vue.use(VueRouter);

Vue.config.devtools = true

export const bus = new Vue();

// Direct only authenticated users with a given role to the destination route
// others will be redirected to login
const auth = (roles) => async (to, from, next) => {
    const account = store.state.auth.account;

    // If user is not logged in on load
    if (!account) {
        next({ name: 'login' });
        return;
    }

    const revalidatedAccount = await store.dispatch('auth/revalidateUserLogin');
    if (!(revalidatedAccount && roles.find(role => role === revalidatedAccount.role))) {
        next({ name: 'login' });
        return;
    }
    next();
}

// When user comes back from bbb a url like ab-xyz.something.de/?appointmentId=<Insert Fancy Id Here> is called
// Based on the url something.de does not know where it should route the user if he has chosen to stay logged in.
// Because of this we lookup the saved user in vuex and route the window according to the user role.
const rerouteLoggedInUser = async (to, from, next) => {
    const { account, stayLoggedIn } = store.state.auth;

    // If user is not logged in on load
    if (!account || !stayLoggedIn) {
        next({ name: 'login' });
        return;
    }

    const revalidatedAccount = await store.dispatch('auth/revalidateUserLogin');
    if (!revalidatedAccount) {
        next({ name: 'login' });
        return;
    }

    switch (revalidatedAccount.role) {
        case 'pupil':
            next({ name: 'schueler.plan', query: to.query });
            return;
        default:
            next({ name: 'login', query: to.query });
    }
};

const routes = [
    {
        path: '',
        component: Home,
    },
    {
        path: '*',
        component: Home,
    },
    { path: '*\\.map' }, // Exclude Javascript maps from vue router https://github.com/vuejs/vue-router/issues/927#issuecomment-350221829
    { path: '*\\.js' }, // Exclude js from vue router https://github.com/vuejs/vue-router/issues/927#issuecomment-350221829
    { path: '*\\.css' }, // Exclude css from vue router https://github.com/vuejs/vue-router/issues/927#issuecomment-350221829
    {
        path: '/version',
        name: 'version',
        component: Version,
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: Impressum,
    },
    {
        path: '/start',
        name: 'start',
        component: Home,
    },
    {
        path: '/training',
        name: 'training',
        component: Training,
    },
    {
        path: '/praktikum',
        name: 'intern',
        component: Intern,
    },
    {
        path: '/ausbildung',
        name: 'apprentice',
        component: Apprentice,
    },
    {
        path: '/betrieb',
        name: 'company',
        component: Company,
    },
    {
        path: '/betriebanlegen',
        name: 'betriebanlegen',
        component: CompanyForm,
    },
    {
        path: '/berichtanlegen',
        name: 'berichtanlegen',
        component: TraineeForm,
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

function hasQueryParams(route) {
    return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
    if (!hasQueryParams(to) && hasQueryParams(from)) {
        next({ name: to.name, query: from.query });
    } else {
        next()
    }
});

const main = new Vue({
    store,
    router,
    vuetify,
    icons: {
        iconfont: 'fa',
    },
    render: h => h(App)
}).$mount('#app');

