import * as backend from '../../api/backend';
import { checkResponseStatus } from "@/util/check";

const actions = {};

const mutations = {};

const getters = {};

const state = {};

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}
