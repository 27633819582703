<template>
    <v-bottom-navigation
        app
        style="height: fit-content; left: 50vw; transform: translateX(-50%); background-color: #707070; z-index: 100"
        :style="windowWidth < 800 ? 'width: 100vw; gap: 20px' : 'width: fit-content;'"
        background-color="#eaeaea"
    >
        <div
            v-for="item in items"
            :key="item.title"
            style="margin: 0 1vw"
            class="d-flex flex-column justify-center align-center py-2"
        >
            <v-btn
                class="navTile"
                color="white"
                elevation="0"
                x-small
                tile
                v-ripple
                x-large
                @click="$router.replace(item.route)"
            >
                <img
                    :src="item.icon"
                    style="max-width: 48px;"
                    :style="item.transform ? 'transform: rotate(' + item.transform + ')' : ''"
                    alt=""
                >
            </v-btn>
            <div style="color: white; font-size: 16px;">
                {{ item.title }}
            </div>
        </div>
    </v-bottom-navigation>
</template>

<script>
import start from '@/assets/logo only.png'
import training from '@/assets/training.png'
import intern from '@/assets/intern.png'
import apprentice from '@/assets/apprentice.png'
import {mapState} from "vuex";

export default {
    name: "BottomNavigation",
    data() {
        return {
            items: [
                { title: 'Start', route: 'start', icon: start },
                { title: 'Training', route: 'training', icon: training, transform: '135deg' },
                { title: 'Praktikum', route: 'praktikum', icon: intern, transform: '135deg' },
                { title: 'Ausbildung', route: 'ausbildung', icon: apprentice, transform: '135deg' },
            ],
        }
    },
    computed: {
        ...mapState('util', ['windowWidth']),
    },
}
</script>

<style scoped>
.navTile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6px;
    width: 48px !important;
    min-width: 48px !important;
    max-width: 48px !important;
    height: 48px;
    min-height: 48px !important;
    max-height: 48px !important;
}

.navTile:hover {
    filter: brightness(0.8);
}

@media (max-width: 600px) {
    #navContainer {
        justify-content: space-between;
        gap: unset;
    }
}
</style>
