const defaultState = {
  // show: false,
  snackbarMessageNumber: 0,
  snackbarColor: 'success',
  snackbarTimeout: 5000,
  snackbarMessage: '',
  snackbarClosable: true,
};

const mutations = {
  showSnackbar(state, {color= 'success', timeout=5000, message= "", closable= true}) {
    // Increment message number because vue watches for MessageNumber
    // and shows snackbar even when same message is shown again and again
    state.snackbarMessageNumber = (state.snackbarMessageNumber + 1) % 3;  // Keep the number small
    state.snackbarColor = color;
    state.snackbarTimeout = timeout;
    state.snackbarMessage = message;
    state.snackbarClosable = closable;
  },
};

const actions = {

};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
