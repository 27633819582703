<template>
    <v-app>
        <router-view />
        <div style="width: 100%">
            <BottomNavigation />
        </div>
    </v-app>
</template>

<script>
import {mapActions, mapState} from "vuex";
import * as backend from "./api/backend";
import BottomNavigation from "@/components/BottomNavigation";

export default {
    name: "App",
    components: {BottomNavigation },
    data: () => ({
        backendResponse: "",
        backendResponseFailed: false,
    }),
    computed: {
        ...mapState('util', ['windowWidth']),
    },
    watch: {},
    async mounted() {
        window.addEventListener('resize', this.handleViewHeightChange);
        this.handleViewHeightChange();

        console.log('Requesting backend');
        try {
            const helloFromBackendResponse = await backend.getHelloFromBackend();
            this.backendResponse = await helloFromBackendResponse.text();
            console.log(this.backendResponse);
        } catch (err) {
            console.error(err);
            this.backendResponseFailed = true;
            console.log('Requesting backend failed');
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleViewHeightChange);
    },
    methods: {
        ...mapActions('util', ['setWindowWidth', 'setWindowHeight']),

        // height change to fit browser bar
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        handleViewHeightChange() {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);

            // Timeout is needed for windowWidth because on Safari Mobile,
            // under certain circumstances the new innerWidth takes too long to calculate,
            // in which case we safe the old one
            setTimeout(() => {
                this.setWindowWidth(window.innerWidth);
                this.setWindowHeight(window.innerHeight);
            }, 400);
        },
    },
};
</script>

<style lang="scss">
// universal settings for font
.v-application {
    font-size: 20px;
}

.visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.icon {
    width: 48px;
}

</style>
