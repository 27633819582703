const defaultState = {
  // Is false when backend is down or e.g. no dns entry is found
  backendReachable: true,
};

const mutations = {
  setBackendReachable(state, backendReachable) {
    state.backendReachable = backendReachable;
  },
};

const actions = {
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
