<template>
    <div
        id="background"
        style="padding-bottom: 100px"
        :style="tokenValid ? '' : 'display: none'"
    >
        <div
            class="d-flex flex-column"
            style="margin: 100px 10vw 0 10vw"
        >
            <v-card
                outlined
                background-color="white"
                style="display: flex; flex-direction: column; width: 70%; margin: auto"
            >
                <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start; background-color: white; border-radius: 5px; padding-top: 10px; padding-left: 20px; padding-bottom: 50px; padding-right: 10px">
                    <div style="width: 100%; display: flex; justify-content: space-between; margin-bottom: 20px">
                        <div>
                            <span><b> Verwaltung </b> </span> <br>
                        </div>
                        <v-btn
                            large
                            color="green"
                            elevation="0"
                            class="text-none"
                            dark
                            tile
                            style="margin-right: 10px"
                            @click="dialogToken = true; tokenType = 'Company'"
                        >
                            Betrieb-Link generieren
                        </v-btn>
                    </div>
                    <span> Um Praktikumsberichte zu erstellen oder einen Betrieb anlegen zu können, wird immer ein Link benötigt.</span>
                    <span> Mit den grünen Schaltflächen generieren Sie einen neuen Link und können diesen dann verteilen.</span>
                    <br>
                </div>
                <br>
                <!-- Token Array -->
                <div
                    v-for="(item) in tokens"
                    :key="item._id"
                    style="display: flex; justify-content: space-around;"
                >
                    <v-card
                        outlined
                        class="mb-2"
                        style="max-width: 50vw; min-width: 50vw"
                        :style="item.type === 'Trainee' ? 'margin-right: -40px' : ''"
                    >
                        <v-list-item>
                            <v-list-item-avatar
                                tile
                                size="80"
                            >
                                <v-icon large>
                                    {{ item.type === 'Trainee' ? 'mdi-account' : 'mdi-domain' }}
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <div
                                    class="text-overline mb-4"
                                    style="display: flex; justify-content: space-between;"
                                >
                                    <span> {{ item.type === 'Trainee' ? 'Bericht' : 'Betrieb' }} </span>
                                    <span> {{ unpackDate(item) }} </span>
                                </div>
                                <v-list-item-title
                                    class="mb-2"
                                    style="max-width: 80%"
                                >
                                    <span> Notiz: </span>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ item.description }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-card-actions
                            style="display: flex; justify-content: space-between"
                        >
                            <v-btn
                                tile
                                dark
                                elevation="0"
                                style="background-color: #ff4b3d99"
                                class="text-none"
                                @click="openDialogDelete(item)"
                            >
                                Löschen
                            </v-btn>
                            <div style="display: flex">
                                <v-tooltip
                                    :disabled="!item.disabled"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on">
                                            <v-btn
                                                v-if="item.type === 'Company'"
                                                class="white--text text-none"
                                                tile
                                                elevation="0"
                                                style="background-color: #4CAF50; margin-right: 20px"
                                                :disabled="item.disabled"
                                                @click="openDialogFromCompany(item)"
                                            >
                                                Bericht-Link generieren
                                            </v-btn>
                                        </div>
                                    </template>
                                    <span>Der Betrieb wurde vom Empfänger noch nicht eingerichtet</span>
                                </v-tooltip>
                                <v-btn
                                    tile
                                    dark
                                    elevation="0"
                                    class="text-none"
                                    style="background-color: dodgerblue;"
                                    @click="copyURL(item)"
                                >
                                    Link kopieren
                                </v-btn>
                            </div>
                        </v-card-actions>
                    </v-card>
                </div>
            </v-card>
            <!-- Generate Token Overlay -->
            <v-dialog
                :value="dialogToken"
                color="white"
                width="50%"
                @click:outside="() => { dialogToken = false; this.description = ''; this.tokenType = 'Company'; }"
            >
                <div>
                    <v-card
                        outlined
                    >
                        <v-card-title>
                            <span> {{ 'Link generieren für: '+(tokenType === 'Trainee' ? 'Schüler oder Schülerin' : 'Betrieb') }}</span>
                        </v-card-title>
                        <v-list-item>
                            <v-list-item-content>
                                <!-- <v-radio-group
                                    v-model="tokenType"
                                >
                                    <v-radio
                                        v-for="(type) in types"
                                        :key="(type)"
                                        :label="type === 'Trainee' ? 'Schüler oder Schülerin' : 'Betrieb'"
                                        :value="(type)"
                                    />
                                </v-radio-group> -->
                                <span> Notiz: </span>
                                <v-text-field
                                    v-model.lazy="description"
                                    label="Für wen ist dieser Link?"
                                />
                            </v-list-item-content>
                        </v-list-item>
                        <v-card-actions
                            style="display:flex;justify-content:flex-end;"
                        >
                            <v-btn
                                tile
                                dark
                                color="grey"
                                elevation="0"
                                class="text-none"
                                @click="() => { dialogToken = false; this.description = ''; this.tokenType = 'Company'; }"
                            >
                                Abbrechen
                            </v-btn>
                            <v-btn
                                tile
                                dark
                                color="green"
                                elevation="0"
                                class="text-none"
                                @click="generateToken"
                            >
                                Link generieren
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </div>
            </v-dialog>
            <!-- Dialog Delete Token -->
            <v-dialog
                color="white"
                width="200px"
                :value="dialogDelete"
                @click:outside="() => { dialogDelete = false;}"
            >
                <v-card
                    outlined
                >
                    <v-card-title style="display: flex; justify-content: center">
                        <span> Sicher löschen? </span>
                    </v-card-title>
                    <v-card-actions style="display: flex; justify-content: center">
                        <v-btn
                            tile
                            dark
                            style="background-color: #ff4b3d99"
                            elevation="0"
                            class="text-none"
                            @click="deleteToken(tokenToDelete)"
                        >
                            Löschen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <div
                ref="copyUrlContainer"
                type="text"
            />
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import * as backend from "../api/backend";
import dateformat from 'dateformat';

export default {
    name: "Apprentice",
    data() {
        return {
            // meta
            tokenValid: false,

            // data
            tokens: null,

            //dialog delete token
            dialogDelete: false,
            tokenToDelete: null,

            // dialog create new token
            dialogToken: false,
            tokenType: 'Company',
            companyRef: null,
            // types: ['Trainee', 'Company'],
            description: '',
        }
    },
    computed: {
        ...mapState("admin",["adminToken"]),
    },
    async mounted() {
        await this.refreshTokens();
        // console.log(this.adminToken);
        const res = await backend.validateAdminToken(this.adminToken);
        if (res.status === 200) {
            this.tokenValid = true;
        }
    },
    methods: {
        async refreshTokens() {
            let res = await backend.getTokenAll();
            this.tokens = await res.json();
            this.tokens = this.tokens.map((token) => {
                token.url = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + (token.type === 'Trainee' ? '/berichtanlegen?' : '/betriebanlegen?') + token.value;
                return token;
            });
            let tokensSorted = [];
            await Promise.all(this.tokens.map(async (token) => {
                if (token.type === 'Company') {
                    let res = await backend.validateCompany(token.value);
                    if (res.status === 403) {
                        token.disabled = true;
                    } else {
                        token.disabled = false;
                    }
                    let group = [];
                    group.push(token);
                    this.tokens.forEach((traineeToken) => {
                        if (token._id === traineeToken.companyRef) {
                            group.push(traineeToken);
                        }
                    });
                    tokensSorted.push(...group);
                    }
            }));
            this.tokens = tokensSorted;
            console.log(this.tokens);
        },
        async copyURL(item) {
            const tempInput = document.createElement("input");
            tempInput.value = item.url;
            this.$refs.copyUrlContainer.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            this.$refs.copyUrlContainer.removeChild(tempInput);
        },

        async deleteToken(item) {
            let body = {};
            body.adminToken = this.adminToken;
            let res = await backend.deleteToken(item._id, body);
            if (res) {
                window.alert(res.status+': Token wurde gelöscht');
            }
            this.refreshTokens();
            this.dialogDelete = false;
            this.tokenToDelete = null;
        },

        async openDialogDelete(item) {
            this.tokenToDelete = item;
            this.dialogDelete = true;
        },

        async generateToken() {
            let body = {};
            body.type = this.tokenType;
            body.description = this.description;
            body.adminToken = this.adminToken;
            if (this.tokenType === 'Trainee') {
                body.companyRef = this.companyRef;
            }
            await backend.generateToken(body);
            this.refreshTokens();
            this.dialogToken = false;
            this.description = '';
            this.companyRef = null;
            this.tokenType = 'Company';
        },

        // this should only be allowed when companyModel has been created
        async openDialogFromCompany(item) {
            this.tokenType = 'Trainee';
            this.companyRef = item._id;
            this.dialogToken = true;
        },

        unpackDate(item) {
            if (item.time) {
                return (dateformat(item.time, "isoDate") + ' ' + dateformat(item.time, "isoTime"));
            }
            return '';
        },
    },
}
</script>

<style scoped>
.icon {
    width: 50px;
    height: 50px;
}
.noShadow {
    box-shadow: none !important;
}

.v-dialog {
    box-shadow: none !important;
}

#background {
    background-color: lightgrey;
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../assets/Training.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
    height: 100%;
}

</style>
