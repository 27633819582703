import * as backend from '@/api/backend';
import { checkResponseStatus } from "@/util/check";

const actions = {
    async getCategories({ commit, dispatch }, params) {
        try {
            const res = await backend.getCategory();
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            return err.response.status;
        }
    },

    async getCategory({ commit, dispatch}, params) {
      try {
          const res = await backend.getCategory(params);
          await checkResponseStatus(200, res);
          return await res.json();
      } catch (err) {
          return err.response.status;
      }
    },

    async postCategory({ commit, dispatch }, params) {
        try {
            const res = await backend.postCategory(params);
            await checkResponseStatus(201, res);
            return await res.json();
        }
        catch (err) {
            return err.response.status;
        }
    },

    async deleteCategory({ commit }, categoryId) {
        try {
            const res = await backend.deleteCategory(categoryId);
            await checkResponseStatus(201, res);
            return await res.json();
        } catch (e) {
            return e;
        }
    },

    async uploadLogoCategory ({ commit, dispatch}, params) {
        try {
            const categoryId = params.categoryId;
            delete params.categoryId;
            const res = await backend.uploadLogoCategory(categoryId, params);
            await checkResponseStatus(201, res);
            return await res.json();
        }
        catch(err) {
            return err.response.status;
        }
    },
};

const mutations = {};

const getters = {};

const state = {};

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}
