<template>
    <div style="margin: 2vh 5vw">
        <v-btn
            v-if="$route.name !== 'start'"
            class="text-none"
            elevation="0"
            color="red"
            dark
            tile
            @click="$router.replace({name: 'start'})"
        >
            zurück
        </v-btn>

        <h1 class="mt-10 mb-4">
            Impressum
        </h1>

        <h2>Herausgeber</h2>
        <p>
            Land Baden-Württemberg, vertreten durch die<br>
            <br>
            Hardbergschule<br>
            Pfalzgraf-Otto-Straße 41<br>
            74821 Mosbach<br>
            <br>
            Telefon: 06261 – 12 215<br>
            Fax: 03212 – 10 333 94<br>
            Mail: hardbergschule@t-online.de<br>
            <br>
            Schulträger: Stadt Mosbach<br>
            <br>
            Vertretungsberechtigte: Tilo Bödigheimer<br>
            <br>
        </p>

        <h2>Verantwortlich im Sinne von § 55 RStV</h2>
        <p>
            Hardbergschule<br>
            Tilo Bödigheimer<br>
            Pfalzgraf-Otto-Straße 41<br>
            74821 Mosbach<br>
            <br>
            Telefon: 06261 – 12 215<br>
            Fax: 03212 – 10 333 94<br>
            Mail: hardbergschule@t-online.de<br>
            <br>
        </p>

        <h2>Rechtliche Hinweise:</h2>
        <p>
            Urheberrecht<br>
            Texte, Bilder, Grafiken und Fotos sowie die Gestaltung dieser Internetseiten unterliegen dem Urheberrecht.
            Sie dürfen von Ihnen nur zum privaten und sonstigen eigenen Gebrauch im Rahmen des § 53 Urheberrechtsgesetz
            (UrhG) ohne jede Änderung vervielfältigt werden. Eine Verbreitung von Kopien (analog oder digital) oder
            Auszügen ist nur mit schriftlicher Genehmigung zulässig. Der Nachdruck und die Auswertung von
            Pressemitteilungen und Reden sind mit Quellenangabe gestattet. Texte, Bilder, Grafiken und sonstige
            Materialien können ganz oder teilweise dem Urheberrecht Dritter unterliegen. Die Inhalte Dritter sind als
            solche kenntlich gemacht und dürfen ohne Einwilligung des Rechteinhabers nicht vervielfältigt, öffentlich
            zugänglich gemacht oder öffentlich wiedergegeben werden.<br>
            <br>

            Haftung für Inhalte<br>
            Die Informationen, die Sie auf diesem Internetauftritt vorfinden, wurden nach bestem Wissen und Gewissen
            sorgfältig zusammengestellt und geprüft. Für die Richtigkeit, Vollständigkeit, Aktualität oder Qualität und
            jederzeitige Verfügbarkeit der bereit gestellten Informationen wird jedoch keine Gewähr übernommen.
            Diensteanbieter sind gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
            verantwortlich. Diensteanbieter sind jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
            Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
            bleiben hiervon unberührt.
            <br>

            Dieser Internetauftritt enthält auch Links oder Verweise auf Internetauftritte Dritter. Diese Links zu den
            Internetauftritten Dritter stellen keine Zustimmung zu deren Inhalten durch den Herausgeber dar.
            Für die Inhalte der externen Links sind die jeweiligen Anbieter oder Betreiber (Urheber) der Seiten
            verantwortlich. Mit den Links zu anderen Internetauftritten wird den Nutzern lediglich der Zugang zur
            Nutzung der Inhalte vermittelt.<br>
            <br>

            Die externen Links wurden zum Zeitpunkt der Linksetzung nach bestem Wissen und Gewissen auf eventuelle
            Rechtsverstöße überprüft. Eine ständige inhaltliche Überprüfung der externen Links ist ohne konkrete
            Anhaltspunkte einer Rechtsverletzung nicht möglich. Bei Verlinkungen auf die Webseiten Dritter, die
            außerhalb des Verantwortungsbereichs des Herausgebers liegen, würde eine Haftungsverpflichtung nur bestehen,
            wenn der Herausgeber von den rechtswidrigen Inhalten Kenntnis erlangen und es technisch möglich und zumutbar
            wäre, die Nutzung dieser Inhalte zu verhindern. Sollten Sie der Ansicht sein, dass die verlinkten externen
            Seiten gegen geltendes Recht verstoßen oder sonst unangemessene Inhalte haben, so teilen Sie uns dies bitte
            mit.<br>
            <br>
        </p>

        <div style="height: 100px" />
    </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
    name: "Impressum",
    methods: {
    }
}
</script>

<style scoped>

</style>
