import * as backend from '../../api/backend';

const actions = {
  async getFile ({commit, dispatch}, params) {
    try {
      const res = await backend.getFile(params);
      return res;
    }
    catch(err) {
      return err.response.status;
    }
  },
}

export default {
  namespaced: true,
  actions,
};
