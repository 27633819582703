<template>
    <div id="background">
        <div
            :style="windowWidth < 800 ? 'margin: 2vh 16px 0 16px' : 'margin: 2vh 20vw 4vh 20vw'"
            style="padding-bottom: 100px;"
        >
            <Breadcrumbs :route="crumbs" />

            <v-btn
                class="text-none"
                elevation="0"
                tile
                color="red"
                dark
                @click="$router.go(-1)"
            >
                zurück
            </v-btn>

            <div
                class="d-flex flex-column align-center justify-space-between"
                style="height: 50vh"
                @click="clickLink"
            >
                <div class="d-flex flex-column justify-center align-center">
                    <img
                        :src="apprentice"
                        style="width: 120px"
                        alt=""
                    >
                    <p
                        style="font-size: 30px; font-weight: bold"
                        class="mb-8"
                    >
                        <b>Ausbildung</b>
                    </p>
                </div>

                <div
                    id="tile"
                    class="d-flex flex-column align-center pa-2"
                    style="gap: 20px; background-color: rgba(255, 255, 255, 0.8); border: 1px solid black; padding-top: 40px !important"
                >
                    <div class="my-4">
                        <img
                            style="width: 200px; transform: rotate(-45deg)"
                            class="my-4"
                            :src="apprentice"
                            alt=""
                        />
                    </div>

                    <p
                        class="mt-4 text-center"
                        style="font-size: 24px"
                    >
                        <b>Dieser Abschnitt befindet sich <br> noch in der Entwicklung.</b>
                    </p>
                </div>

                <a
                    id="bbw"
                    href="https://www.bbw-mosbach-heidelberg.de"
                >
                    Link zu BBW
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import apprentice from '@/assets/apprentice.png';

export default {
    name: "Apprentice",
    components: {Breadcrumbs},
    data() {
        return {
            apprentice,

            crumbs: [
                {
                    text: 'Ausbildung',
                }
            ],
        }
    },
    computed: {
        ...mapState('util', ['windowWidth']),
    },
    async mounted() {
    },
    methods: {
        clickLink() {
            document.getElementById('bbw').click();
        },
    },
}
</script>

<style scoped>
#background {
    background-color: lightgrey;
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../assets/Ausbildung.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
}

#tile {
    cursor: pointer;
}
#tile:hover {
    transform: scale(1.25);
}
</style>
